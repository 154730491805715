<template>
  <article>
    <Content>
      <div class="col-12">
        <div class="card-header">
          <div class="alert alert-info" role="alert">購物車</div>
        </div>
        <div class="card-body">
          <!-- 訂購清單 -->
          <div class="row">
            <div class="col-12">
              <ul class="list-group">
                <li class="list-group-item" v-for="(item, index) in orderList" :key="index">
                  <div class="row">
                    <div class="col-md-2 col-sm-12">序號：{{ index + 1 }}</div>
                    <div class="col-md-4 col-sm-12">
                      商品名稱：{{ item.productName }}<br />
                      <span v-if="item.productNumber != 'pd005'">
                        商品價格：{{ item.productPrice }}<br />
                        商品運費：{{ item.productFare }}
                      </span>
                      <span v-else> 商品內容：<a href="javascript:void(0)" @click="showDetail">點此展開明細</a> </span>
                    </div>
                    <div class="col-md-4 col-sm-12">
                      服務店家：{{ item.storeName }}<br />
                      服務人員：{{ item.storeStaff }}<br />
                      <span v-if="item.productNumber != 'pd005'">
                        訂購數量：{{ item.amount }}<br />
                        訂購小計：{{ item.productTotalPrice }}
                        <div v-if="item.remark" style="color: red">{{ item.remark }}</div>
                      </span>
                      <span v-else> 訂購小計：{{ ticketTotalPrice }}</span>
                    </div>
                    <div class="col-md-2 col-sm-12">
                      <div style="text-align: center">
                        <Button :label="'刪除此商品'" :btnColor="'red'" @click="removeProduct(index)"></Button>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <!-- 付款資訊 -->
          <div class="row" v-if="orderList.length > 0">
            <div class="col-12" id="sum-section">
              <div class="form-group row">
                <label class="col-5 col-form-label col-form-label-lg">消費金額：</label>
                <div class="col-7">
                  <InputText v-model="model.orderPrice" :disabled="true" />
                  <div v-show="approveDiscounted" style="color: red">
                    本次折抵: {{ totalDiscountedPrice }}，剩餘金額為: {{ model.orderPrice - totalDiscountedPrice }}
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-5 col-form-label col-form-label-lg">收件人姓名：</label>
                <div class="col-7">
                  <InputText v-model="model.receiverName" />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-5 col-form-label col-form-label-lg">收件人電話：</label>
                <div class="col-7">
                  <InputText v-model="model.receiverPhone" />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-5 col-form-label col-form-label-lg">收件人地址：</label>
                <div class="col-7">
                  <InputText v-model="model.receiverAddress" />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-5 col-form-label col-form-label-lg">付款方式：</label>
                <div class="col-7">
                  <select class="custom-select custom-select-lg" v-model="model.paymentType" @change="initOrderList">
                    <option v-for="(item, index) in paymentTypeList" :value="item.value" :key="index">
                      {{ item.label }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-5 col-form-label col-form-label-lg">備註：</label>
                <div class="col-7">
                  <InputText v-model="model.remark" />
                </div>
              </div>
              <div class="form-group row" v-show="isVIPAndNotAgent">
                <label class="col-5 col-form-label col-form-label-lg">VIP擁有點數：</label>
                <div class="col-7">
                  <InputText v-model="totalDiscountedPrice" :disabled="true" />
                  <Checkbox
                    :id="'checkDiscountedId'"
                    :label="'VIP消費金額高於擁有點數，點數可全數折抵'"
                    :isDisabled="model.orderPrice < totalDiscountedPrice"
                    @input="checkDiscounted" />
                </div>
              </div>
            </div>
          </div>
          <!-- 按鈕 -->
          <div class="row mt-5" v-if="orderList.length > 0">
            <div class="d-block mx-auto">
              <Button :label="'送出訂單'" :btnColor="'green'" class="mx-1" @click="submit"></Button>
              <Button :label="'清空購物車'" :btnColor="'red'" class="mx-1" @click="clearCart"></Button>
            </div>
          </div>
          <!-- 氣能康富收款帳號 -->
          <div class="row mt-3" v-if="orderList.length > 0">
            <div class="col-12 alert alert-info" role="alert" style="margin-bottom: 0; text-align: center; font-size: 1.5rem">
              點下方賴詢問 氣能康富收款帳號
            </div>
          </div>
          <!-- 補下單 -->
          <div class="row mt-3" v-if="isAdmin">
            <div class="col-12">
              <div class="form-group row">
                <label class="col-12 col-form-label col-form-label-lg" style="font-size: 2rem">補下單(只可系統管理員使用)</label>
              </div>
              <div class="form-group row">
                <label class="col-5 col-form-label col-form-label-lg">補下單使用者之USER ID：</label>
                <div class="col-7">
                  <InputText v-model="backUserId" />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-5 col-form-label col-form-label-lg">補下單日期：</label>
                <div class="col-7">
                  <InputText v-model="backOrderDate" placeholder="YYYY-MM-DD" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Content>
    <!-- 票券明細 -->
    <Modal :id="'modal_detail'">
      <span slot="header"><div style="font-size: 1.7rem">店內票券</div></span>
      <ul class="list-group list-group-flush">
        <li class="list-group-item" v-for="(item, index) in ticketList" :key="index">
          <div class="row" style="font-size: 1.5rem">
            <div class="col-12">
              票券名稱：{{ item.productName.split("@")[0] }}<br />
              票券價格：{{ item.productName.split("@")[1] + " " + item.price }}<br />
              票券數量：{{ item.amount }}
            </div>
          </div>
        </li>
      </ul>
    </Modal>
    <!-- 契約內容 -->
    <Modal :id="'modal_contract'">
      <span slot="header">
        <div style="font-size: 1.7rem">
          <span v-if="productNumberContract == 'pd001-1' || productNumberContract == 'pd001-4'">《氣能康富氣功墊短期租借合約書》</span>
          <span v-else-if="productNumberContract == 'pd003'">《氣能康富活力機長期租借合約書》</span>
          <span v-else-if="productNumberContract == 'pd003-1'">《氣能康富活力機短期租借合約書》</span>
        </div>
      </span>
      <span slot="footer">
        <button v-show="agree" @click="confirmContract" type="button" class="btn btn-lg btn-success mx-1" data-dismiss="modal">同意送出</button>
      </span>
      <div class="card-body">
        <p class="card-text ml-2">
          <span v-if="productNumberContract == 'pd001-1' || productNumberContract == 'pd001-4'">
            茲因甲方可提供氣功墊設備及相關維修服務，乙方有意願付費短期租借氣功墊。雙方基於誠信原則，同意簽立本合約，並履行下列各項約定：<br />
            <br />
            一、 氣功墊短期租借約定：<br />
            &nbsp;(一) 甲方提供乙方短期租借之氣功墊，氣功墊到貨後，請乙方盡速測試各項功能是否正常，如有任何問題請向甲方反映。<br />
            &nbsp;(二) 短期租借氣功墊，租借者需付押金新台幣15000元，押金於退租時歸還。<br />
            <br />
            二、 氣功墊維修服務約定：<br />
            &nbsp;(一) 乙方租借之氣功墊因人為或災害因素損壞，由乙方負擔全部維修費用(含運費)。<br />
            &nbsp;(二) 人為及災害因素係指人為使用不當，具體情況如下：<br />
            &nbsp;&nbsp;1. 沒有按照使用說明正常使用氣功墊設備。<br />
            &nbsp;&nbsp;2. 人為因素對氣功墊設備進行敲擊或踩踏、或使用工具、化學品等方式損壞氣功墊。<br />
            &nbsp;&nbsp;3. 自行將氣功墊設備拆卸、維修或改造而造成損壞。<br />
            &nbsp;(三) 遺失或無法修復之損壞賠償辦法：<br />
            &nbsp;&nbsp;以購買氣功墊未稅價每台39800計算，賠償給氣能康富股份有限公司。<br />
            <br />
            三、 終止契約條款：<br />
            (一) 乙方租借氣功墊後，如不再使用，可退還甲方，自乙方退還氣功墊之日起，視為終止本合約。<br />
            (二) 乙方退還氣功墊時，不得要求甲方退回全部或一部分租借費用。<br />
            <br />
            四、 禁止商業用途及禁止轉售約定：<br />
            &nbsp;(一)
            乙方向甲方短期租借之氣功墊不可用於商業用途(例如在營業場合供客戶免費使用，或向第三人收取費用，或轉租給第三人等商用情事)。乙方如違反本約定，甲方有權將活力機收回，亦不退還租借費用。<br />
            &nbsp;(二) 乙方如違反本條禁止商業用途，須賠償甲方違約金20萬元。<br />
            <br />
            五、 合意管轄：<br />
            雙方履行本合約如發生爭議，先以協商方式處理，如須進行訴訟時，雙方同意以台灣新竹地方法院為管轄法院。<br />
            <br />
            六、 本合約未約定之事項，概依中華民國法律定之。<br />
          </span>
          <span v-else-if="productNumberContract == 'pd003'">
            茲因甲方可提供活力機設備及相關維修服務，乙方有意願付費長期租借活力機，租借之活力機序號於出貨時由氣能康富公司記錄在氣能康富網站訂單紀錄。雙方基於誠信原則，同意簽立本合約，並履行下列各項約定：<br />
            <br />
            一、 活力機長期租借約定：<br />
            &nbsp;(一)
            甲方提供乙方長期租借之活力機設備皆以新機出貨，活力機到貨後，請乙方盡速檢查活力機外觀是否完好，並測試各項功能是否正常，如有任何問題請向甲方反映。<br />
            &nbsp;(二) 本合約活力機長期租借費用為新臺幣(下同)41800元，乙方可使用信用卡付款。如現金支付，未稅價為41800元，贈送2000現金抵扣券。<br />
            &nbsp;(三) 乙方如需加購活力機之反應器，每個售價為800元。<br />
            &nbsp;(四) 除有本合約所禁止之商業用途、禁止轉售、終止合約之情事，乙方可無限期租借活力機設備。<br />
            <br />
            二、 活力機維修服務約定：<br />
            &nbsp;(一)
            乙方自活力機到貨日起算1年內，如該租借之活力機因非人為或非災害因素損壞，則甲方提供保固維修服務。若該租借之活力機因人為或災害因素損壞，則由乙方負擔全部維修費用(含運費)。<br />
            &nbsp;(二) 乙方租借活力機屆滿12個月以後，若有損壞，由乙方負擔全部維修費用(含運費)。<br />
            &nbsp;(三)
            人為及災害因素係指人為使用不當、發生災害或非活力機本身原因所引起之設備損壞等情形，均不在甲方1年保固維修服務範圍內，具體情況如下：<br />
            &nbsp;&nbsp;1. 沒有按照使用說明正常使用活力機設備。<br />
            &nbsp;&nbsp;2. 人為因素對活力機設備進行敲擊或踩踏、或使用工具、化學品等方式損壞活力機。<br />
            &nbsp;&nbsp;3. 自行將活力機設備拆卸、維修或改造而造成損壞。<br />
            &nbsp;&nbsp;4. 因風災、雨災、地震、火災等災害導致活力機設備損壞。<br />
            &nbsp;&nbsp;5. 從他人轉讓或出售而獲得的二手活力機設備。<br />
            <br />
            三、 終止契約條款：<br />
            &nbsp;(一) 乙方租借活力機後，如不再使用，可退還甲方，自乙方退還活力機之日起，視為終止本合約。 <br />
            &nbsp;(二) 乙方退還活力機時，不得要求甲方退回全部或一部分租借費用。 <br />
            &nbsp;(三) 乙方如有違反本合約，甲方亦得終止本合約，並收回活力機設備。 <br />
            <br />
            四、 禁止商業用途及禁止轉售約定：<br />
            &nbsp;(一)
            乙方向甲方長租借用之活力機設備不可用於商業用途(例如在營業場合供客戶免費使用，或向第三人收取費用，或轉租給第三人等商用情事)。乙方如違反本約定，甲方有權將活力機收回，亦不退還租借費用。<br />
            &nbsp;(二) 乙方租借之活力機不可轉售予第三人。<br />
            &nbsp;(三) 乙方如違反本條禁止商業用途及禁止轉售約定，須賠償甲方違約金20萬元。<br />
            <br />
            五、 合意管轄：<br />
            雙方履行本合約如發生爭議，先以協商方式處理，如須進行訴訟時，雙方同意以台灣新竹地方法院為管轄法院。<br />
            <br />
            六、 本合約未約定之事項，概依中華民國法律定之。<br />
          </span>
          <span v-else-if="productNumberContract == 'pd003-1'">
            茲因甲方可提供活力機設備及相關維修服務，乙方有意願付費短期租借活力機。雙方基於誠信原則，同意簽立本合約，並履行下列各項約定：<br />
            <br />
            一、 活力機短期租借約定：<br />
            &nbsp;(一) 甲方提供乙方短期租借之活力機，活力機到貨後，請乙方盡速測試各項功能是否正常，如有任何問題請向甲方反映。<br />
            &nbsp;(二) 短期租借活力機，租借者需付押金新台幣15000元，押金於退租時歸還。<br />
            &nbsp;(三) 乙方需加購活力機之反應器，每個售價為800元。<br />
            <br />
            二、 活力機維修服務約定：<br />
            &nbsp;(一) 乙方租借之活力機因人為或災害因素損壞，由乙方負擔全部維修費用(含運費)。 <br />
            &nbsp;(二) 人為及災害因素係指人為使用不當，具體情況如下：<br />
            &nbsp;&nbsp;1. 沒有按照使用說明正常使用活力機設備。 <br />
            &nbsp;&nbsp;2. 人為因素對活力機設備進行敲擊或踩踏、或使用工具、化學品等方式損壞活力機。 <br />
            &nbsp;&nbsp;3. 自行將活力機設備拆卸、維修或改造而造成損壞。 <br />
            &nbsp;(三) 遺失或無法修復之損壞賠償辦法：<br />
            &nbsp;&nbsp;以活力機長租未稅價每台39800計算，賠償給氣能康富股份有限公司。<br />
            <br />
            三、 終止契約條款：<br />
            &nbsp;(一) 乙方租借活力機後，如不再使用，可退還甲方，自乙方退還活力機之日起，視為終止本合約。<br />
            &nbsp;(二) 乙方退還活力機時，不得要求甲方退回全部或一部分租借費用。<br />
            <br />
            四、 禁止商業用途及禁止轉售約定：<br />
            &nbsp;(一)
            乙方向甲方短期租借之活力機不可用於商業用途(例如在營業場合供客戶免費使用，或向第三人收取費用，或轉租給第三人等商用情事)。乙方如違反本約定，甲方有權將活力機收回，亦不退還租借費用。<br />
            &nbsp;(二) 乙方如違反本條禁止商業用途，須賠償甲方違約金20萬元。<br />
            <br />
            五、 合意管轄：<br />
            雙方履行本合約如發生爭議，先以協商方式處理，如須進行訴訟時，雙方同意以台灣新竹地方法院為管轄法院。<br />
            <br />
            六、 本合約未約定之事項，概依中華民國法律定之。<br />
          </span>
        </p>
        <Checkbox :id="'checkContractId'" :label="'打勾即為同意以上租借辦法。'" @input="checkContract" />
      </div>
    </Modal>
  </article>
</template>

<script>
import InputText from "@/components/style/InputText"
import Button from "@/components/style/Button"
import Modal from "@/components/style/Modal"
import Checkbox from "@/components/style/Checkbox"
import Content from "@/components/style/Content"

import moment from "moment"
import Cookies from "js-cookie"
export default {
  name: "Cart",
  components: {
    InputText,
    Button,
    Modal,
    Checkbox,
    Content,
  },
  data() {
    return {
      storeList: [],
      discountedList: [],
      totalDiscountedPrice: 0,
      approveDiscounted: false,
      orderList: [],
      ticketList: [],
      ticketTotalPrice: 0,
      model: {
        orderPrice: 0,
        paymentType: "2",
        receiverName: null,
        receiverPhone: null,
        receiverAddress: null,
        remark: null,
        passContract: false,
      },
      productNumberContract: null,
      agree: false,
      backOrderDate: null,
      backUserId: null,
    }
  },
  methods: {
    async removeProduct(productIndex) {
      let product = this.orderList.find((item, index) => index == productIndex)
      let confirmed = await this.$Swal.fire({
        icon: "warning",
        title: "再次確認",
        text: `是否刪除${product.productName}?`,
        showCancelButton: true,
        confirmButtonText: "確認刪除",
        cancelButtonText: "取消",
      })
      if (!confirmed.value) {
        return
      }

      this.$store.dispatch("removeOrderList", productIndex)
      this.orderList = this.$store.state.orderList
      this.initOrderList()
    },
    showDetail() {
      $("#modal_detail").modal("show")
    },
    initOrderList() {
      let paymentInfo = Cookies.get("paymentInfo")
      if (paymentInfo) {
        this.model = { ...this.model, ...JSON.parse(paymentInfo) }
        Cookies.remove("paymentInfo")
      }

      this.model.orderPrice = 0
      this.ticketTotalPrice = 0
      this.orderList.forEach(item => {
        if (item.productNumber != "pd005") {
          let productFare = item.productFare * item.amount
          item.productTotalPrice = item.productPrice * item.amount + productFare
          this.model.orderPrice += item.productTotalPrice
        }
        item.storeName = item.storeId ? this.storeList.find(v => v.storeId == item.storeId).storeName : "無"
      })

      let existTicket = this.orderList.find(item => item.productNumber == "pd005")
      if (existTicket) {
        this.ticketList = existTicket.ticketList.filter(item => item.amount > 0)
        this.ticketList.forEach(item => {
          this.ticketTotalPrice += item.price * item.amount
        })
      } else {
        this.ticketList = []
        this.ticketTotalPrice = 0
      }

      this.model.orderPrice += this.ticketTotalPrice
    },
    async clearCart() {
      let confirmed = await this.$Swal.fire({
        icon: "warning",
        title: "再次確認",
        text: `是否清空購物車?`,
        showCancelButton: true,
        confirmButtonText: "確認清空",
        cancelButtonText: "取消",
      })
      if (!confirmed.value) {
        return
      }

      this.$store.dispatch("removeOrderList")
      this.orderList = []
      this.ticketList = []
      this.ticketTotalPrice = 0
      this.model = Object.assign(
        {},
        {
          totalPrice: 0,
          paymentType: "1",
          receiverName: null,
          receiverPhone: null,
          receiverAddress: null,
          remark: null,
        }
      )
    },
    async submit() {
      let checkOrderInfo = this.orderList.some(item => item.productNumber != "pd005" && !this.constMemberList.includes(item.productNumber))
      if (checkOrderInfo) {
        let errorMessage = ""
        if (!this.model.paymentType) {
          errorMessage += "請選擇付款方式<br>"
        }
        if (errorMessage) {
          return this.$Swal.fire({ icon: "error", title: "異常訊息", html: `${errorMessage}` })
        }
        if (!this.model.passContract) {
          let openContract = false
          this.productNumberContract = null
          this.orderList.forEach(item => {
            if (!openContract) {
              let productNumber = item.productNumber
              if (productNumber == "pd001-1" || productNumber == "pd001-4" || productNumber == "pd003" || productNumber == "pd003-1") {
                this.productNumberContract = productNumber
                openContract = true
              }
            }
          })
          if (openContract) {
            this.showContract()
            return
          } else {
            this.model.passContract = true
          }
        }
      }

      if (!this.isLogin) {
        let obj = Object.assign({}, this.model)
        delete obj.orderPrice
        Cookies.set("paymentInfo", JSON.stringify(obj), { expires: 0.4 })
        Cookies.set("orderList", JSON.stringify(this.orderList), { expires: 0.4 })

        await this.$Swal.fire({
          icon: "info",
          title: "訊息",
          text: "目前尚未登入，即將進行登入",
        })
        return this.$store.dispatch("checkLogin")
      }

      //只有買會員方案才需要檢查會員資格
      if (!this.membershipValid) {
        let existsMemberProduct = this.orderList.some(item => this.constMemberList.includes(item.productNumber))
        let existsTriggerMemberProduct = this.orderList.some(item => item.productNumber == "pd015" || item.productNumber == "pd016")
        if (existsMemberProduct && !existsTriggerMemberProduct) {
          return this.$Swal.fire({ icon: "error", title: "異常訊息", text: `請下單會員費` })
        }
      }

      let obj = Object.assign({}, this.model)
      if (!checkOrderInfo && !this.model.receiverName) {
        obj.receiverName = ""
      }
      obj.orderDetailList = this.orderList
        .map(item => {
          let isTicket = item.productNumber == "pd005"
          let ticketList = isTicket
            ? this.ticketList.map(
                ticket => ticket.productNumber + "@" + ticket.amount + "@" + ticket.productName.split("@")[1].replace("張票", "").trim()
              )
            : []
          let detailPrice = 0
          if (isTicket) {
            detailPrice = this.ticketTotalPrice
          } else {
            detailPrice = item.productPrice
          }
          return {
            productNumber: item.productNumber,
            detailAmount: isTicket ? 0 : item.amount,
            detailPrice,
            detailFare: isTicket ? 0 : item.productFare,
            storeId: item.storeId,
            storeStaff: item.storeStaff,
            ticketList,
          }
        })
        .sort((a, b) => {
          const [baseA, suffixA] = a.productNumber.replace("pd", "").split("-")
          const [baseB, suffixB] = b.productNumber.replace("pd", "").split("-")

          const baseCompare = parseInt(baseA) - parseInt(baseB)

          if (baseCompare === 0 && (suffixA || suffixB)) {
            if (!suffixA) return -1
            if (!suffixB) return 1
            return parseInt(suffixA) - parseInt(suffixB)
          }

          return baseCompare
        })

      if (this.approveDiscounted && this.isVIPAndNotAgent) {
        obj.approveDiscountedIdList = this.discountedList.map(v => v.id)
      }

      // 補下單
      if (this.isAdmin) {
        if (!this.backOrderDate && this.backUserId) {
          return this.$Swal.fire({ icon: "error", title: "異常訊息", text: `請輸入補下單日期` })
        } else if (this.backOrderDate && !this.backUserId) {
          return this.$Swal.fire({ icon: "error", title: "異常訊息", text: `請輸入補下單使用者之USER ID` })
        } else if (!moment(this.backOrderDate, "YYYY-MM-DD", true).isValid()) {
          return this.$Swal.fire({ icon: "error", title: "異常訊息", text: `請輸入正確的日期 (YYYY-MM-DD)` })
        }

        obj.backOrderDate = this.backOrderDate
        obj.backUserId = this.backUserId
      }

      try {
        let response = await this.$http.post("/order/createOrder", obj)
        if (response.data.errorMsg) {
          return this.$Swal.fire({ icon: "error", title: "異常訊息", text: `${response.data.errorMsg}` })
        }

        await this.$Swal.fire({
          icon: "success",
          title: "成功訊息",
          text: `完成訂單，訂單號碼：${response.data}`,
        })

        this.$store.dispatch("removeOrderList")
        this.$router.push({ path: "/order" })
      } catch (error) {
        return this.$Swal.fire({ icon: "error", title: "系統發生錯誤", text: String(error) })
      }
    },
    showContract() {
      this.agree = false
      $("#checkContractId").prop("checked", false)
      $("#modal_contract").modal("show")
    },
    checkContract(payload) {
      this.agree = payload.checked
    },
    confirmContract() {
      this.model.passContract = true
      this.submit()
    },
    async searchVIPDiscounted() {
      try {
        let response = await this.$http.get("/bonus/searchDiscountedList")
        if (response.data.errorMsg) {
          return this.$Swal.fire({ icon: "error", title: "異常訊息", text: `${response.data.errorMsg}` })
        }

        this.discountedList = response.data
        this.totalDiscountedPrice = this.discountedList.reduce((sum, item) => sum + item.applyPrice, 0)
      } catch (error) {
        return this.$Swal.fire({ icon: "error", title: "系統發生錯誤", text: String(error) })
      }
    },
    checkDiscounted(payload) {
      this.approveDiscounted = payload.checked
    },
  },
  computed: {
    isLogin() {
      return this.$store.getters.isLogin()
    },
    membershipValid() {
      let membershipDate = this.isLogin ? this.$store.state.userInfo.userProfile.membershipDate : ""
      if (membershipDate) {
        let now = moment().format("YYYY-MM-DD")
        if (!moment(now).isSameOrBefore(membershipDate)) {
          return false
        }
      } else {
        return false
      }
      return true
    },
    isVIPAndNotAgent() {
      if (!this.$store.getters.isLogin()) {
        return false
      }
      return this.$store.state.userInfo.userProfile.agent != "Y" || this.$store.state.userInfo.userProfile.vipFlag == "Y"
    },
    isAdmin() {
      return this.$store.getters.isAdmin()
    },
  },
  async mounted() {
    try {
      let response = await this.$http.get("/store/searchList?enable=Y")
      if (response.data.errorMsg) {
        return this.$Swal.fire({ icon: "error", title: "異常訊息", text: `${response.data.errorMsg}` })
      }

      this.storeList = response.data

      let orderList = Cookies.get("orderList")
      if (orderList) {
        this.orderList = JSON.parse(orderList)
      } else {
        this.orderList = this.$store.state.orderList
      }
      this.initOrderList()

      if (this.isVIPAndNotAgent) {
        this.searchVIPDiscounted()
      }
    } catch (error) {
      return this.$Swal.fire({ icon: "error", title: "系統發生錯誤", text: String(error) })
    }
  },
}
</script>

<style scoped>
.card-header {
  text-align: center;
  padding: 0;
}
.card-header div {
  margin-bottom: 0;
  font-size: 1.5rem;
  font-weight: bold;
}
.list-group-item div,
#sum-section div label {
  font-size: 1.5rem;
}
#sum-section div label {
  text-align: right;
}
#modal_contract .card-title {
  font-size: 1.6rem;
  font-weight: bold;
  color: black;
}
#modal_contract .card-body {
  font-size: 1.5rem;
}
</style>
