import Vue from "vue"
import Vuex from "vuex"
import Cookies from "js-cookie"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    userInfo: {
      userProfile: null,
      childList: [],
      ticketList: [],
    },
    loading: false,
    orderList: [],
  },
  actions: {
    async loginCallback({ commit, dispatch }, token) {
      dispatch("updateToken", token)
      try {
        const response = await this.$http.post("/user/searchMember")
        if (response.data.errorMsg) {
          return this.$Swal.fire({
            icon: "error",
            title: "異常訊息",
            text: `${response.data.errorMsg}`,
          })
        }
        commit("SET_USER_INFO", response.data)
        Cookies.set("shareUserId", response.data.userProfileModel.userId, { expires: 30 })

        let previousRoute = Cookies.get("previousRoute")
        if (previousRoute) {
          Cookies.remove("previousRoute")
          this.$router.push(previousRoute)
        } else {
          this.$router.push("/user")
        }
      } catch (error) {
        return this.$Swal.fire({
          icon: "error",
          title: "系統發生錯誤",
          text: String(error),
        })
      }
    },
    checkLogin({ getters }, nextRoute) {
      if (!getters.isLogin()) {
        Cookies.set("previousRoute", !nextRoute ? this.$router.app._route.path : nextRoute, { expires: 0.4 })
        this.$router.push({
          path: "/line-login",
          query: { lineUrl: this.$mixin.data().line_url },
        })
      }
    },
    searchMember({ commit }) {
      return new Promise((resolve, reject) => {
        this.$http
          .post("/user/searchMember")
          .then(response => {
            if (response.data.errorMsg) {
              reject(response.data.errorMsg)
            } else {
              commit("SET_USER_INFO", response.data)
              resolve()
            }
          })
          .catch(error => {
            reject(String(error))
          })
      })
    },
    storeOrderList({ commit, state, getters }, payload) {
      commit("SET_ORDERLIST", payload)
      Cookies.set("orderList", JSON.stringify(state.orderList), { expires: 0.4 })
    },
    removeOrderList({ commit, state }, productIndex) {
      if (productIndex != undefined) {
        commit("REMOVE_ITEM_ORDERLIST", productIndex)
      } else {
        commit("CLEAR_ORDERLIST")
      }
      if (state.orderList.length == 0) {
        Cookies.remove("orderList")
      } else {
        Cookies.set("orderList", JSON.stringify(state.orderList), { expires: 0.4 })
      }
    },
    updateToken({ commit }, token) {
      commit("SET_TOKEN", token)
      Cookies.set("token", token, { expires: 1 })
    },
  },
  mutations: {
    SET_TOKEN(state, payload) {
      state.token = payload
    },
    SET_USER_INFO(state, payload) {
      state.userInfo.userProfile = payload.userProfileModel
      state.userInfo.childList = payload.childList
      state.userInfo.ticketList = payload.ticketList
    },
    SET_LOADING(state, payload) {
      state.loading = payload
    },
    SET_ORDERLIST(state, payload) {
      let exists = state.orderList.some(item => item.productNumber == payload.productNumber)
      if (!exists) {
        state.orderList.push({
          productNumber: payload.productNumber,
          productName: payload.productName,
          productPrice: payload.productPrice,
          productFare: payload.productFare,
          amount: payload.amount,
          storeId: payload.storeId,
          storeStaff: payload.storeStaff,
          ticketList: payload.ticketList,
        })
      } else {
        state.orderList.forEach(item => {
          if (item.productNumber == payload.productNumber) {
            item.productNumber = payload.productNumber
            item.productName = payload.productName
            item.productPrice = payload.productPrice
            item.productFare = payload.productFare
            item.amount = payload.amount
            item.storeId = payload.storeId
            item.storeStaff = payload.storeStaff
            item.ticketList = payload.ticketList
          }
        })
      }
    },
    CLEAR_ORDERLIST(state) {
      state.orderList = []
    },
    REMOVE_ITEM_ORDERLIST(state, removedIndex) {
      state.orderList.splice(removedIndex, 1)
    },
    MINUS_AMOUNT_TICKETLIST(state, payload) {
      state.userInfo.ticketList.forEach(item => {
        if (item.orderNumber == payload.orderNumber && item.productNumber == payload.productNumber) {
          if (item.productNumber == "pd005-14" || item.productNumber == "pd005-15") {
            item.availableAmount = item.availableAmount - item.useAmount
          } else {
            item.availableAmount -= 1
          }
        }
      })
    },
  },
  getters: {
    isLogin: state => () => {
      return state.userInfo.userProfile != null
    },
    getToken: state => () => {
      return state.token || Cookies.get("token")
    },
    isAdmin: state => () => {
      return state.userInfo.userProfile != null && state.userInfo.userProfile.isAdmin == "Y"
    },
  },
})
