import { render, staticRenderFns } from "./Bonus.vue?vue&type=template&id=fa6dd9da&scoped=true"
import script from "./Bonus.vue?vue&type=script&lang=js"
export * from "./Bonus.vue?vue&type=script&lang=js"
import style0 from "./Bonus.vue?vue&type=style&index=0&id=fa6dd9da&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa6dd9da",
  null
  
)

export default component.exports